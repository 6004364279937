import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './styles/scss/App.scss';
import Amplify from 'aws-amplify'
import config from './config/aws-exports'
import { I18n } from "aws-amplify"
import { Translations } from "@aws-amplify/ui-components";
import './App.css';
import  logo from './assets/img/brand/logo.png';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react'
//import 'bootstrap/dist/css/bootstrap.min.css';
Amplify.configure(config)

/*I18n.putVocabulariesForLanguage("es", {
  [Translations.SIGN_IN_HEADER_TEXT]: "NEXA-PKSOLUCIONES",
  [Translations.SIGN_IN_ACTION]: "Login",

});*/
const dict = {
  es: {
    'Sign in to your account':' ',
    'Username *': 'Usuario:',
    'Password *': 'Contraseña',
    'Enter your username': 'Ingrese su usuario',
    'Enter your password': 'Ingrese su contraseña',
    'LOGIN': 'Iniciar Session',
    'Sign In': 'Iniciar Session',
    'Reset password': ': Aqui',
    'Forgot your password?': ': Olvidaste tu contraseña?',
    'Reset your password': 'Restablecer su contraseña',
    'Back to Sign In': 'Volver a iniciar sesión',
    'Send Code': 'Enviar Codigo'

  }
};
I18n.putVocabularies(dict);

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));


class App extends Component {

  render() {

    return (
      <>
      {/* <div className="logoNexaPK">
      <img  src={logo} alt="Mi logotipo" />
      </div>  */}
      <div>
      <AmplifyAuthenticator >
        <AmplifySignIn slot="sign-in" hideSignUp ></AmplifySignIn>
          <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </AmplifyAuthenticator>
     </div>
     </>
    );
  }
}
export default App