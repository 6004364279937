import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './config/polyfill'
import * as serviceWorker from './config/serviceWorker';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/css/index.css';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
